import Highcharts from "highcharts";
import Common from "./common";

const Charts = {

    initCharts: function () {
        let charts = document.querySelectorAll('.js-chart');
        if (charts.length) {
            charts.forEach(chart => {
                if (chart.classList.contains('-bar')) {
                    this.initBarCharts(chart);
                } else if (chart.classList.contains('-pie')) {
                    this.initPieCharts(chart);
                }
            });
        }
    },

    initBarCharts: function (el) {
        let colors = Common.parseJson(el.getAttribute('data-colors')) || ["#55b948", "#c6ecc1"];
        let categories =  Common.parseJson(el.getAttribute('data-categories'));
        let series =  Common.parseJson(el.getAttribute('data-series'));

        Highcharts.setOptions({
            lang: {
                thousandsSep: '.',
                numericSymbols: [null, ' mio']
            },
        });
        var myChart = Highcharts.chart(el.id, {
            chart: {
                type: 'column',
                styledMode: true
            },

            colors: colors,

            title: {
                text: ''
            },
            xAxis: {
                tickWidth: 0,
                labels: {
                    style: {
                        color: '#fff',
                        textOverflow: 'none'
                    },
                    autoRotation: [0],
                    step: 0,
                },
                categories: categories
            },


            yAxis: [{
                className: 'highcharts-color-0',
                title: {
                    text: ''
                },
                allowDecimals: true,
                labels: {
                    enabled: true,
                }
            },],

            plotOptions: {
                column: {
                    width: 15,
                    tooltip: {
                        distance: 10,
                    },
                }
            },

            legend: {
                enabled: false
            },

            tooltip: {
                padding: 10,
                shadow: false,

                formatter: function () {
                    return Highcharts.numberFormat(this.y, 0);
                },
            },

            series: [{
                data: series,
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    // Make the labels less space demanding on mobile
                    chartOptions: {
                        xAxis: {
                            labels: {
                                step: 2,
                            }
                        },
                        yAxis: {
                            labels: {
                                enabled: false,
                            },
                        }
                    }
                }]
            }

        });

    },

    initPieCharts: function (el) {
        let totalText = el.getAttribute('data-totalText') || "Total";
        let tootlTipText = el.getAttribute('data-tootlTipText') || "in";
        let series =  Common.parseJson(el.getAttribute('data-series'));

        Highcharts.chart(el.id, {
            chart: {
                styledMode: true,

                events: {
                    load: function(event) {
                        var chart = this,
                            points = chart.series[0].points,
                            len = points.length,
                            total = 0,
                            i = 0;

                        for (; i < len; i++) {
                            total += points[i].y;
                        }

                        chart.setTitle({
                            useHTML: true,
                            text: '<div><p class="pie-title">' + total + '</p><p class="pie-subtitle">' + totalText + '</p></div>',
                            align: 'center',
                            verticalAlign: 'middle',
                            y: -20,
                            style: {
                                fontWeight: 'bold',
                                zIndex: 0,
                            },
                        });
                    }
                }
            },

            title: {
                text: ''
            },

            tooltip: {
                shadow: false,
                useHTML: true,
                outside: true,
                padding: 0,
                formatter: function() {
                    return '<div class="tootltip"><p class="highcharts-tooltip-title">' + this.y + '</p><p  class="highcharts-tooltip-subtitle"><small>' + tootlTipText + ' ' + this.key + '</small></p></div>';
                },
            },

            series: [{
                type: 'pie',
                allowPointSelect: true,
                keys: ['name', 'y'],
                data: series,
                size: '100%',
                innerSize: '90%',
                showInLegend: false,
                dataLabels: {
                    enabled: false
                }
            }],


        });
    },

};

export default Charts;