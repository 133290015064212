import moment from 'moment';
import Common from './common.js';
import 'moment/locale/fr.js';
import 'moment/locale/de.js';

const DatePicker = {

    pickers: [],

    initPicker: function () {
        let self = this;

        const $pickers = $('.js-datetimepicker');

        let language = $('html').attr('lang');

        moment.locale(language);

        $pickers.find('input[type="date"]').attr('type', 'text');
        $pickers.find('input[type="time"]:not(".-inline")').attr('type', 'text');


        $pickers.each(function () {
            let $this = $(this);

            if ($this.hasClass('date')) {
                if ($this.hasClass('-inline')) {
                    self.initInlineDatePicker($this, language);
                } else {
                    self.initDatePicker($this, language);
                }
            } else if ($this.hasClass('time') && !$this.hasClass('-inline')) {
                self.initTimePicker($this, language);
            }

        });

        this.initTwoMonthpicker();

        //links two date pickers date together
        $('.js-linkedDateStart').on("change.datetimepicker", function(e) {
            $('.js-linkedDateEnd').datetimepicker('minDate', e.date);
        });
        $('.js-linkedDateEnd').on("change.datetimepicker", function(e) {
            $('.js-linkedDateStart').datetimepicker('maxDate', e.date);
        });
    },

    initDatePicker: function (el, language) {
        let self = this;
        let format = 'YYYY-MM-DD';
        let date = this.setDate(el.attr('data-date')) || moment().format('YYYY-MM-DD');
        let maxDate = this.setDate(el.attr('data-maxDate')) || false;
        let minDate = this.setDate(el.attr('data-minDate')) || false;
        let disabledDate = Common.parseJson(el.attr('data-disabledDate'));

        let position = el.attr('data-position');
        let vPosition = el.attr('data-vPosition') || 'auto';
        if (position != 'right') {
            position = 'left';
        }

        el.datetimepicker({
            format: format,
            locale: language,
            useCurrent: true,
            widgetParent: el.parent(),
            date: date ? moment(new Date(date)) : moment(),
            minDate: minDate,
            maxDate: maxDate,
            disabledDates: disabledDate,
            allowInputFocus: true,
            allowInputToggle: true,
            focusOnShow: true,
            widgetPositioning: {
                horizontal: position,
                vertical: vPosition
            },
        });


        this.changeDateLabelValue(el);

        el.on("change.datetimepicker", function () {
            self.changeDateLabelValue($(this), format);
            self.populateModalPicker(el);

        });

        $('body').on('click', function () {
            $('.js-datetimepicker:not(".-inline")').datetimepicker('hide');
        })

    },

    initTimePicker: function (el) {
        let self = this;
        let input = el.find('.datetimepicker-control');

        el.datetimepicker({
            allowInputFocus: true,
            format: "HH:mm",
            defaultDate: moment({}).add(1, 'hours'),
            focusOnShow: true,
            stepping: 10,
            icons: {
                up: "fa fa-chevron-up",
                down: "fa fa-chevron-down"
            }
        });

        el.on("change.datetimepicker", function (e) {
            self.changeTimeLabelValue($(this));
            self.populateModalPicker($(this));
        });

        input.change(function () {
            self.changeWidgetTimeValue(el, input);
        });

    },

    changeDateLabelValue: function (datepicker) {

        let monthFormat = datepicker.attr('data-monthFormat') || "MM";
        let yearFormat = datepicker.attr('data-yearFormat') || "";

        let day = moment(datepicker.datetimepicker("viewDate")).format("D");
        let weekDay = moment(datepicker.datetimepicker("viewDate")).format("dddd");
        let month = moment(datepicker.datetimepicker("viewDate")).format(monthFormat);
        let year = moment(datepicker.datetimepicker("viewDate")).format(yearFormat);

        let labelDay = datepicker.find('.date-day');
        let labelMonth = datepicker.find('.date-month');
        let labelYear = datepicker.find('.date-year');
        let labelWeekDay = datepicker.find('.date-week-day');

        labelDay.text(day < 10 ? "0" + day : day);
        labelWeekDay.text(weekDay);
        labelMonth.text(month);
        labelYear.text(year);

        this.changeDateReference(datepicker);
    },

    changeDateReference: function (datepicker) {
        let now = moment();
        let datereference = moment(datepicker.datetimepicker('date')).calendar(null);
        let label = datepicker.find('.date-reference');

        label.text(datereference);
    },

    changeTimeLabelValue: function (datepicker) {

        let hour = moment(datepicker.datetimepicker("viewDate")).format("HH");
        let minutes = moment(datepicker.datetimepicker("viewDate")).format("mm");

        let labelHour = datepicker.find('.time-hour');
        let labelMinutes = datepicker.find('.time-min');

        labelHour.text(hour);
        labelMinutes.text(minutes);

    },


    initInlineTimePicker: function () {
        let self = this;
        const $pickers = $('.js-datetimepicker.time.-inline');

        $pickers.each(function () {
            $(this).datetimepicker({
                focusOnShow: true,
                allowInputFocus: true,
                format: "HH:mm",
                inline: true,
                date: moment({}).add(1, 'hours'),
                stepping: 10,
                icons: {
                    up: "fa fa-chevron-up",
                    down: "fa fa-chevron-down"
                }
            });

            $(this).on("change.datetimepicker", function (e) {
                self.populateModalPicker($(this));
            });

        });

    },


    initInlineDatePicker: function (el, language) {
        let date = this.setDate(el.attr('data-date')) || moment().format('YYYY-MM-DD');
        let maxDate = this.setDate(el.attr('data-maxDate'), date) || false;

        let minDate;
        if (el.attr("id") === "excursion-start-firstMonth") {
            minDate = this.setDate(el.attr('data-minDate'), date) || false;
        } else {
            minDate = moment(this.setDate(el.attr('data-minDate'), date)).date(1) || false;
        }

        let disabledDate = Common.parseJson(el.attr('data-disabledDate')) || false;
        let enabledDates = Common.parseJson(el.attr('data-enabledDates')) || false;

        //needed to change the dates to iso format
        enabledDates = Object.values(enabledDates).map(date => moment(date));

        el.datetimepicker({
            inline: true,
            format: 'YYYY-MM-DD',
            locale: language,
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: date,
            disabledDates: disabledDate,
            enabledDates: enabledDates,
        });
    },

    setDate: function (el, date) {
        let defaultDate = date || new Date();

        if (el === "today") {
            return moment(defaultDate).format('YYYY-MM-DD');
        } else if (el && el.includes("month")) {
            let months = el.substring(0, el.indexOf('month'));
            return moment(defaultDate).add(months, "months").format('YYYY-MM-DD');
        } else if (el && el.includes("years")) {
            return moment(defaultDate).add(1, "years").format('YYYY-MM-DD');
        } else if (el) {
            return moment(el).format('YYYY-MM-DD');
        }
    },

    initTwoMonthpicker: function () {
        const self = this;
        const twoMonthsDatePicker = $('.js-twoMonthsDatePicker');

        twoMonthsDatePicker.each(function () {
            let pickers = $(this).find('.js-datetimepicker');
            let input = $(this).find('.js-twoMonthSelectedDate');

            let defaultDate = self.setDate($(pickers[0]).attr('data-date')) || moment().format('YYYY-MM-DD');
            input.val(defaultDate);

            let prevBtn = $(this).find('.js-datepickerPrevMonths');
            let nextBtn = $(this).find('.js-datepickerNextMonths');

            self.checkIfBtnDisabled($(pickers).first(), prevBtn, nextBtn);

            self.selectDateInTwoCalendar(pickers);

            $('.bootstrap-datetimepicker-widget').on('click', 'th.next', function () {
                self.addOneMonth(pickers, prevBtn, nextBtn);
            });

            $('.bootstrap-datetimepicker-widget').on('click', 'th.prev', function () {
                self.removeOneMonth(pickers, prevBtn, nextBtn);
            });

            prevBtn.on('click', function () {
                self.removeOneMonth(pickers, prevBtn, nextBtn);
            });

            nextBtn.on('click', function () {
                self.addOneMonth(pickers, prevBtn, nextBtn);
            });
        });
    },

    addOneMonth: function (pickers, prevBtn, nextBtn) {
        let self = this;
        pickers.each(function () {
            let OneMoreMonth = $(this).datetimepicker('date').add(1, 'months');
            let maxDate = $(this).datetimepicker('maxDate');

            if (OneMoreMonth <= maxDate) {
                $(this).datetimepicker('date', OneMoreMonth);
                $(this).find('.day:not(.disabled)').each(function () {
                    $(this).removeAttr('data-action');
                });

                //resetting input to avoid passing a date by error
                let input = $(this).parent().find('.js-twoMonthSelectedDate');
                input.val(null);

            }

            let oldActiveDay = $(this).find('.active');
            oldActiveDay.removeClass('active');

            self.checkIfBtnDisabled($(this), prevBtn, nextBtn);
        })
    },

    removeOneMonth: function (pickers, prevBtn, nextBtn) {
        let self = this;
        pickers.each(function () {
            let OneMonthLess = $(this).datetimepicker('date').subtract(1, 'months');
            let minDate = $(this).datetimepicker('minDate');

            if (OneMonthLess >= minDate) {
                $(this).datetimepicker('date', OneMonthLess);
                // reused again because is added at each date modifications
                $(this).find('.day:not(.disabled)').each(function () {
                    $(this).removeAttr('data-action');
                });
                //resetting input to avoid passing a date by error
                let input = $(this).parent().find('.js-twoMonthSelectedDate');
                input.val(null);

            }

            self.checkIfBtnDisabled($(this), prevBtn, nextBtn);

            let oldActiveDay = $(this).find('.active');
            oldActiveDay.removeClass('active');
        })
    },

    selectDateInTwoCalendar: function (pickers) {
        let self = this;
        pickers.each(function () {

            let day = $(this).find('.day:not(.disabled)');

            // needed to override default behavior
            day.each(function () {
                $(this).removeAttr('data-action');
            });

            $('.bootstrap-datetimepicker-widget').on("click", ".day:not(.disabled)", function (e) {
                self.setDateValueInTwoCalendar(e, pickers);
            });
        });

        let firstPicker = $('.bootstrap-datetimepicker-widget .day.active').first();

        if (firstPicker.hasClass('disabled')) {
            firstPicker.nextAll(':not(.disabled)').first().click();
        } else {
            firstPicker.click();
        }
    },

    setDateValueInTwoCalendar: function (e, pickers) {
        e.preventDefault();
        e.stopImmediatePropagation();

        let picker = $(e.target).closest('.js-datetimepicker');

        // setting the date to null triggers a module bug,
        // had to populate an hidden input with the active date value,
        // active value that is removed on the other calendar when one is selected in a calendar

        pickers.each(function () {
            let oldActiveDay = $(this).find('.active');
            oldActiveDay.removeClass('active');
        });

        $(e.target).addClass('active');

        let monthYear = moment(picker.datetimepicker('date')).format('YYYY-MM');
        let day = parseInt($(e.target).text()) < 10 ? '0' + $(e.target).text() : $(e.target).text();

        let newDate = monthYear + "-" + day;

        let input = picker.parent().find('.js-twoMonthSelectedDate');
        input.val(newDate);

        // reused again because is added at each date modifications
        picker.find('.day:not(.disabled)').each(function () {
            $(this).removeAttr('data-action');
        });
    },

    checkIfBtnDisabled: function (el, prevBtn, nextBtn) {
        let currentDate = el.datetimepicker('date');
        let minDate = el.datetimepicker('minDate');
        let maxDate = el.datetimepicker('maxDate');
        if (currentDate <= minDate) {
            prevBtn.addClass('disabled');
        } else if (currentDate > minDate && currentDate < maxDate) {
            prevBtn.removeClass('disabled');
            nextBtn.removeClass('disabled');
        } else if (currentDate >= maxDate) {
            nextBtn.addClass('disabled');
        }
    },

    changeWidgetTimeValue: function (datepicker, input) {

        let hour = moment($(input).val(), "HH:mm").format("HH");
        let minutes = moment($(input).val(), "HH:mm").format("mm");

        let widgetHour = datepicker.find('.timepicker-hour');
        let widgetMinutes = datepicker.find('.timepicker-minute');

        widgetHour.text(hour);
        widgetMinutes.text(minutes);

        datepicker.datetimepicker('date', moment($(input).val(), 'HH:mm'));
    },

    populateModalPicker: function (el) {

        if(el.hasClass('js-departureDate')) {
            $('.js-departureDate').datetimepicker('date', moment(el.find('input').val(), 'YYYY-MM-DD'));
        } else if(el.hasClass('js-arrivalDate')) {
            $('.js-arrivalDate').datetimepicker('date', moment(el.find('input').val(), 'YYYY-MM-DD'));
        } else if(el.hasClass('js-departureTime')) {
            $('.js-departureTime').datetimepicker('date', moment(el.find('input').val(), 'HH:mm'));
        } else if(el.hasClass('js-arrivalTime')) {
            $('.js-arrivalTime').datetimepicker('date', moment(el.find('input').val(), 'HH:mm'));
        }
    },
};

moment.updateLocale('fr', {
    calendar: {
        lastDay: '[hier]',
        sameDay: '[aujourd\'hui]',
        nextDay: '[demain]',
        nextWeek: '[]',
        lastWeek: '[]',
        sameElse: '[]',
    }
});

moment.updateLocale('de', {
    calendar: {
        lastDay: '[Gestern]',
        sameDay: '[Heute]',
        nextDay: '[Morgern]',
        nextWeek: '[]',
        lastWeek: '[]',
        sameElse: '[]',
    }
});

moment.updateLocale('en', {
    calendar: {
        lastDay: '[yesterday]',
        sameDay: '[today]',
        nextDay: '[tomorrow]',
        nextWeek: '[]',
        lastWeek: '[]',
        sameElse: '[]',
    }
});

export default DatePicker;

