import $ from "jquery";

const SelectTabs = {
    "selectTabsInit": function () {
        //necessary to reach the other functions in the object
        let self= this;
        const selectTabs = $('.js-select');
        if ($(selectTabs)) {
            $(selectTabs).each(function () {

                let $thisTab = $(this);

                if ($(this).hasClass('active')) {
                    self.changeButtontext($thisTab);
                }

                $thisTab.on("click", function () {
                    let $this = $(this);

                    if(!$this.hasClass('-inline')) {
                        self.changeTab($this);
                    }
                });
            });
        }
    },

    "changeTab": function(elem) {
        this.showTab(elem);
        this.changeButtontext(elem);
        this.setSelectedTabToActiveState(elem);
    },

    "showTab": function (elem) {
        //jquery mandatory for bootstrap functions
        $('.js-tabs a#' + elem.attr('id') + '-tabs').tab('show');
    },

    "changeButtontext": function (elem) {
        let selectElem = $(elem).closest('.select__elem');
        let dropdownBtn = $(selectElem).find('.changing-text');
        $(dropdownBtn).text($(elem).text());
    },

    "setSelectedTabToActiveState": function(elem) {
        $(elem).siblings().removeClass('active');
        $(elem).addClass('active');
    }
};
export default SelectTabs;

