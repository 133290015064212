import $ from 'jquery';
import Swiper from "swiper";
import Common from './common.js';

const Sliders = {
    /*
   * Function
   * Checks if at least one slider is in the page and loads it/them
   */
    sliders: [],

    sliderInit: function () {

        let navigationSliders = document.querySelectorAll('.overflow-x__slider .swiper-container');
        // let paginationSliders = document.querySelectorAll('.navigation-slider .swiper-container');

        if (navigationSliders) {
            for (let i = 0; i < navigationSliders.length; i++) {
                let nextBtn = navigationSliders[i].querySelector('.swiper-button-next');
                let prevBtn = navigationSliders[i].querySelector('.swiper-button-prev');

                let spaceBetween = navigationSliders[i].getAttribute('data-space-between');
                let spaceBetweenLg = navigationSliders[i].getAttribute('data-space-between-lg');
                let centeredSlide = navigationSliders[i].getAttribute('data-centeredSlide') === "true" || false;
                let breakpoint = Common.parseJson(navigationSliders[i].getAttribute('data-breakpoint')) || {
                    1000: {
                        spaceBetween: spaceBetweenLg,
                    }
                };

                if (spaceBetween == null) {
                    spaceBetween = 80;
                } else {
                    spaceBetween = parseInt(spaceBetween);
                }

                if (spaceBetweenLg == null) {
                    spaceBetweenLg = 30;
                } else {
                    spaceBetweenLg = parseInt(spaceBetweenLg);
                }

                var options = {
                    direction: "horizontal",
                    spaceBetween: spaceBetween,
                    slidesPerView: 'auto',
                    centeredSlides: centeredSlide,
                    watchOverflow: navigationSliders[i].getAttribute('data-watchOverflow') !== "false",
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                    breakpoints: breakpoint,
                    on: {
                        init: function () {
                            Sliders.setGridSize(this);
                        },

                        resize: function () {
                            Sliders.setGridSize(this);
                        },
                    }
                };

                // Carousel testimonials
                let effect = navigationSliders[i].getAttribute('data-effect');
                let carouselType = navigationSliders[i].getAttribute('data-carousel-type');
                let loop = navigationSliders[i].getAttribute('data-loop') === "false" ? false : true;
                let slidePerview = parseFloat(navigationSliders[i].getAttribute('data-slidePerview')) || 1.5;
                let smallslidePerview = parseFloat(navigationSliders[i].getAttribute('data-smallslidePerview')) || 1;
                let heightAuto = navigationSliders[i].getAttribute('data-heightAuto') === "false" ? false : true;

                if (typeof effect != 'undefined' && effect == 'coverflow') {
                    options['effect'] = 'coverflow';
                    options['loop'] = navigationSliders[i].querySelectorAll('.swiper-slide').length > 1 ? loop : false;
                    options['autoHeight'] = heightAuto;
                    options['centeredSlides'] = true;
                    options['slidesPerView'] = slidePerview;
                    options['grabCursor'] = 'true';
                    options['on'] = {
                        on: {
                            init: function () {
                                Sliders.setGridSize(this);
                            },

                            resize: function () {

                            },
                        }
                    };

                    let stretchValue = navigationSliders[i].clientWidth / 2.3;
                    let depthValue = 200;
                    let depthValueMd = 200;

                    if (carouselType === 'transport') {
                        slidePerview = slidePerview === 1.5 ? 1 : slidePerview;
                        stretchValue = navigationSliders[i].clientWidth / 1.125;
                        depthValueMd = 100;
                        options['loop'] = navigationSliders[i].querySelectorAll('.swiper-slide').length > 1 ? loop : false;
                        options['autoHeight'] = false;
                        options['slidesPerView'] = slidePerview;
                    }

                    //breakpoints based on swiper-container size
                    options['breakpoints'] = {
                        500: {
                            slidesPerView: smallslidePerview,
                            coverflowEffect: {
                                rotate: 0,
                                depth: depthValue,
                                stretch: 0,
                                modifier: 1,
                                slideShadows: false,
                            }
                        },
                        900: {
                            slidesPerView: slidePerview,
                            coverflowEffect: {
                                rotate: 0,
                                depth: depthValueMd,
                                modifier: 1,
                                stretch: stretchValue,
                                slideShadows: false,
                            }
                        },
                    }; // We don't want 30px of grid

                    options['coverflowEffect'] = {
                        rotate: 0,
                        stretch: stretchValue,
                        depth: depthValue,
                        modifier: 1,
                        slideShadows: false,
                    };
                }

                let slide = new Swiper(navigationSliders[i], options);

                if (slide.slides.length < 2) {
                    $(nextBtn, prevBtn).hide();
                }

                if (typeof effect != 'undefined' && effect == 'coverflow') {
                    setTimeout(() => {
                        slide.update();
                    }, 500)
                }


                this.sliders.push(slide);

                this.checkIfBtnNeeded(nextBtn, prevBtn);
                //if no overflow hides the navigation buttons
            }
            //@TODO: opacity 0.5 on sliders not fully in window
        }
    },

    setGridSize: function (slide) {
        let slideElement = slide.el.querySelector('.swiper-slide');

        //sets the grid that will be translated depending the slides width and the space between them
        for (let i = 0; i < slide.snapGrid.length; i++) {
            if (i === 0) {
                slide.snapGrid[i] = -0;
                slide.slidesGrid[i] = -0;
            } else {
                slide.snapGrid[i] = (slideElement.clientWidth + slide.params.spaceBetween) * i;
                slide.slidesGrid[i] = (slideElement.clientWidth + slide.params.spaceBetween) * i;
            }

            if (slideElement) {
                slide.slidesSizesGrid[i] = slideElement.clientWidth;
            }
        }
    },

    checkIfBtnNeeded: function (nextBtn, prevBtn) {
        if (nextBtn.classList.contains('swiper-button-disabled') && prevBtn.classList.contains('swiper-button-disabled')) {
            nextBtn.style.display = "none";
            prevBtn.style.display = "none";
        }
    },

    updateSliders: function () {
        for (let i = 0; i < this.sliders.length; i++) {
            this.sliders[i].update();
            let nextBtn = this.sliders[i].el.querySelector('.swiper-button-next');
            let prevBtn = this.sliders[i].el.querySelector('.swiper-button-prev');

            if (nextBtn && prevBtn) {
                this.checkIfBtnNeeded(nextBtn, prevBtn);
            }


        }
    },


    initNormalSlider: function () {

        let navigationSliders = document.querySelectorAll('.normal__slider .swiper-container');

        if (navigationSliders) {
            for (let i = 0; i < navigationSliders.length; i++) {
                let nextBtn = navigationSliders[i].querySelector('.swiper-button-next');
                let prevBtn = navigationSliders[i].querySelector('.swiper-button-prev');

                let spacebetween = parseInt(navigationSliders[i].getAttribute('data-spacebetween')) || 40;
                let smallspacebetween = parseInt(navigationSliders[i].getAttribute('data-smallspacebetween')) || 30;
                let slidesPerview = parseFloat(navigationSliders[i].getAttribute('data-slidePerview')) || 3;
                let smallSlidesPerview = parseFloat(navigationSliders[i].getAttribute('data-smallslidePerview')) || 2.5;

                let autoHeight = navigationSliders[i].getAttribute('data-autoHeight') || false;
                let allowTouchMove = navigationSliders[i].getAttribute('data-allowTouchMove');
                let simulateTouch = navigationSliders[i].getAttribute('data-simulateTouch');
                let loop = navigationSliders[i].getAttribute('data-loop') || false;

                let navigation = navigationSliders[i].getAttribute('data-navigation') || {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                };

                let hashNavigation = Common.parseJson(navigationSliders[i].getAttribute('data-hashNavigation')) || false;

                let breakpoint = Common.parseJson(navigationSliders[i].getAttribute('data-breakpoint')) || {
                    765: {
                        spaceBetween: smallspacebetween,
                        slidesPerView: smallSlidesPerview,
                    }
                };

                var options = {
                    autoHeight: autoHeight,
                    spaceBetween: spacebetween,
                    slidesPerView: slidesPerview,
                    loop: navigationSliders[i].querySelectorAll('.swiper-slides').length > 1 ? loop : false,
                    navigation: navigation,
                    hashNavigation: hashNavigation,
                    watchOverflow: navigationSliders[i].getAttribute('data-watchOverflow') !== "false",

                    breakpoints: breakpoint,
                    allowTouchMove: allowTouchMove ? false : true,
                    simulateTouch: simulateTouch ? false : true,
                };

                let slider = new Swiper(navigationSliders[i], options);

                /*if (slider.slides.length < 2) {
                    $(nextBtn, prevBtn).hide();
                }*/

                slider.update();
                this.sliders.push(slider);

                this.initCounterOnSlideChange(slider);
                this.oneSlideHideBtn(slider, nextBtn, prevBtn);
            }
        }
    },

    initNoOverflowSlide: function () {
        let navigationSliders = document.querySelectorAll('.js-noOverflowSlider .swiper-container');

        if (navigationSliders) {
            for (let i = 0; i < navigationSliders.length; i++) {
                let nextBtn = navigationSliders[i].querySelector('.swiper-button-next');
                let prevBtn = navigationSliders[i].querySelector('.swiper-button-prev');

                let slide = new Swiper(navigationSliders[i], {
                    spaceBetween: 30,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                });

                this.sliders.push(slide);
            }
        }
    },

    initFullscreen: function () {
        let fullscreenBtn = document.querySelectorAll('.js-fullscreenButton, .js-noFullScreenBtn .js-fullscreenImg');
        fullscreenBtn.forEach(btn => {
            btn.addEventListener("click", function (e) {
                let container = e.target.closest('.js-fullScreenContainer');
                let img = container.querySelector('.js-fullscreenImg');
                let imgSrc = container.getAttribute('data-fullscreen-img');

                let fullscreenmodal = document.getElementById("fullscreenModal");
                let modalImg = fullscreenmodal.querySelector('.js-modalImg');
                modalImg.src = imgSrc;
                modalImg.alt = img.alt;
            });
        });
    },

    oneSlideHideBtn: function(slider, nextBtn, prevBtn) {
        if(slider.slides.length === 1) {
            slider.navigation.prevEl && slider.navigation.prevEl.classList.add('d-none');
            slider.navigation.nextEl && slider.navigation.nextEl.classList.add('d-none');
        }
    },

    initHashNavigation: function () {
        let hashNav = document.querySelectorAll('.js-hashNav');

        this.checkActifLinkOnHash();

        if (hashNav.length) {
            window.addEventListener("hashchange", this.checkActifLinkOnHash, false);
        }
    },

    checkActifLinkOnHash: function () {
        let currentHash = window.location.hash.substr(1);
        let hashLinks = [...document.querySelectorAll('.js-hashNavLink')];

        hashLinks.forEach(link => link.classList.remove('active'));

        let linktToActivate = hashLinks.find(el => el.href.split('#')[1] === currentHash);


        if (!currentHash && hashLinks.length) {
            hashLinks[0].classList.add('active');
        } else if (linktToActivate) {
            linktToActivate.classList.add('active');
        }
    },

    initCoverflow: function () {

        let navigationSliders = document.querySelectorAll('.coverflow__slider .swiper-container');

        if (navigationSliders) {
            for (let i = 0; i < navigationSliders.length; i++) {
                let nextBtn = navigationSliders[i].querySelector('.swiper-button-next');
                let prevBtn = navigationSliders[i].querySelector('.swiper-button-prev');

                let spacebetween = parseInt(navigationSliders[i].getAttribute('data-spacebetween')) || 40;
                let slidesPerview = parseFloat(navigationSliders[i].getAttribute('data-slidePerview')) || 3;

                let loop = navigationSliders[i].getAttribute('data-loop') || false;

                let navigation = navigationSliders[i].getAttribute('data-navigation') || {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                };

                let breakpoint = Common.parseJson(navigationSliders[i].getAttribute('data-breakpoint')) || {
                    765: {
                        spaceBetween: 0,
                        slidesPerView: slidesPerview,
                    }
                };

                let stretchValue = navigationSliders[i].clientWidth / 2.3;


                var options = {
                    slidesPerView: slidesPerview,
                    loop: navigationSliders[i].querySelectorAll('.swiper-slide').length > 1 ? loop : false,
                    navigation: navigation,
                    simulateTouch: true,
                    effect: 'coverflow',
                    centeredSlides: true,
                    grabCursor: true,
                    watchOverflow: navigationSliders[i].getAttribute('data-watchOverflow') !== "false",
                    coverflowEffect: {
                        rotate: 0,
                        stretch: stretchValue,
                        depth: 200,
                        modifier: 1,
                        slideShadows: true,
                    },
                };

                let slide = new Swiper(navigationSliders[i], options);

                slide.update();

                this.sliders.push(slide);

            }
        }
    },


    initSliderOnTabAndDropdown: function () {

        let self = this;

        $('.js-sliderDropdown').on('shown.bs.dropdown', function () {
            self.onChangeSlideInit(self);
        });

        $('.js-sliderTab').on('shown.bs.tab', function () {
            self.onChangeSlideInit(self);
        });
    },

    onChangeSlideInit: function (self) {
        let normalSliders = document.querySelectorAll('.normal__slider .swiper-container');
        let coverflowSliders = document.querySelectorAll('.coverflow__slider .swiper-container');

        if (normalSliders.length || coverflowSliders.length) {
            $('.swiper-container').each(function () {
                if (this.swiper) {
                    this.swiper.destroy(true, true);
                }
            });
        }

        if (normalSliders.length) {
            self.initNormalSlider();
        }

        if (coverflowSliders.length) {
            self.initCoverflow();
        }
    },

    updateSliderAfterCollapseClose: function () {
        let self = this;
        $('.js-collapseSlide').on('hidden.bs.collapse', function () {
            self.updateSliders();
        })
    },

    initCounterOnSlideChange: function (slide) {
        let counters = slide.$el.find('.counter');
        if (counters.length) {
            slide.on("slideChange", function() {
                Common.initCounter();
            });
        }
    },
};

export default Sliders;