class DisableAutocomplete {
    constructor(name, id) {
        this.originalName =  name;
        this.name = "";
        this.id = id;
    }

    randomId() {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }

    giveBackOriginalName() {
        return this.originalName;
    }

    giveRandomName() {
        return this.originalName + this.randomId();
    }

}

export default  DisableAutocomplete;