import "@babel/polyfill";

// this will let us have a global jQuery object
window.$ = window.jQuery = require("jquery");

// Modernizr global :)
// window.Modernizr = require('modernizr');

// needed for datepicker
window.moment = require('moment');
// moment.locale($('html').attr('lang'));

// bootstrap datepicker component
require("tempusdominus-bootstrap-4");

import 'bootstrap';
import SmoothScroll from 'smooth-scroll';
import Sliders from './sliders';
import DatePicker from './datepicker';
import Charts from './charts';
import Pannel from './pannel';
import Map from './map';
import 'simplebar';
import counterUp from './counter';
import Tabs from './tabs';
import './jquery.matchHeight-min';
import svg4everybody from 'svg4everybody'
import {disableBodyScroll, enableBodyScrol, clearAllBodyScrollLocks} from 'body-scroll-lock';
import SelectForms from "./select-forms";
import Autocomplete from "./autocomplete";
import Stickyfill from 'stickyfilljs';
import 'objectFitPolyfill';

const Common = {
    "windowWidth": $(window).innerWidth(),

    "mqBreakpoints": {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        xxxl: 1600,
    },

    timeout: null,

    /*
   * Function
   * Expand aria-controls elements
   */
    "ariaControls": function () {
        if ($('.js-aria-control').length > 0) {
            var $trigger = $();
            $(document).on('click', '.js-aria-control', function (e) {
                e.preventDefault();

                var $_this = $(this),
                    $_targetEl = $('#' + $_this.attr('aria-controls')),
                    state = $_this.attr('aria-expanded') !== 'false';

                $_this.attr('aria-expanded', !state);
                $_targetEl.attr('aria-hidden', state);
            });
        }
    },
    /*
   * Function
   * Check visibility for ARIA controlled element and add attributes (hidden and expanded)
   */
    "checkVisibility": function (id, breakpoint) {
        var $_el = $('#' + id),
            controller = $('[aria-controls=' + id + ']');

        if ($(window).innerWidth() <= breakpoint) {
            $_el.attr('aria-hidden', true);
            controller.attr('aria-expanded', false);
        } else {
            $_el.attr('aria-hidden', false);
            controller.attr('aria-expanded', true);
        }
    },

    /*
    * Function
    * When window is resized, re-check visibility
    */
    "watchVisibility": function () {
        let self = this;
        // If orientation change (for mobile and tablet)
        window.addEventListener("orientationchange", function () {
            // Change windowWidth
            self.windowWidth = $(window).innerWidth();
        }, false);

        $(window).resize(function () {
            let breakpoint = '';
            let MainNavClasses = $('#main-nav').closest('.navbar-main').attr('class');
            $.each(MainNavClasses.split(' '), function (k, v) {
                if (v.indexOf("navbar-expand-") >= 0) {
                    breakpoint = v.substring(v.length - 2);
                }
            });

            // Check if width really change (mobile consider scrolling as a width change)
            if ($(window).innerWidth() !== self.windowWidth) {
                let list = Object.keys(self.mqBreakpoints);
                self.checkVisibility('main-nav', self.mqBreakpoints[list[list.indexOf(breakpoint)]]);
            }
            //Makes a difference between mega menu on mobile and desktop
            if ($(window).innerWidth() >= self.mqBreakpoints[breakpoint]) {
                $('.navbar-collapse.show').removeClass('show');
            }
            Sliders.updateSliders();
            Pannel.updatePannel();
            DatePicker.initPicker();
        });
    },

    "isMobileOrTabletDevice": function () {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    },

    "getMobileOperatingSystem": function () {
        var userAgent = navigator.userAgent || navigator.vendor || this.window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }


        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !$(window).MSStream) {
            return "iOS";
        }

        if (/msie/i.test(userAgent) || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return "IE";
        }

        return "unknown";
    },

    "getPlatform": function() {
        var userAgent = navigator.userAgent || navigator.vendor || this.window.opera;

        if (/Windows/i.test(userAgent)) {
            return "Windows";
        }

        if (/Macintosh/i.test(userAgent)) {
            return "Mac";
        }
    },

    "getBrowser": function() {
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return('opera');
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return('chrome');
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return('safari');
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
            return('firefox');
        } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
            return('ie');
        } else {
            return('unknown');
        }
    },

    "parseJson": function (el) {
        el = typeof el !== "string"
            ? JSON.stringify(el)
            : el;

        try {
            el = JSON.parse(el);
        } catch (e) {
            return false;
        }

        if (typeof el === "object" && el !== null) {
            return el;
        }

        return false;
    },

    "setAppLinkToGoodOS": function () {
        const IOSLink = "https://itunes.apple.com/lu/app/cfl-mobile/id406175215?l=fr&mt=8";
        const AndroidLink = "https://play.google.com/store/apps/details?id=de.hafas.android.cfl&hl=fr";

        switch (this.getMobileOperatingSystem()) {
            case "IOS":
                $('.js-app-link').each(function () {
                    let $_this = $(this);
                    $_this.attr("href", IOSLink);
                });
                break;
            case "Android":
                $('.js-app-link').each(function () {
                    let $_this = $(this);
                    $_this.attr("href", AndroidLink);
                });
                break;
            default:
                $('.js-app-link').each(function () {
                    let $_this = $(this);
                    $_this.attr("href", AndroidLink);
                });
                break;
        }
    },

    "closeFullWidthAlert": function () {
        $('.alert.-full-width').on('click', function (e) {
            e.preventDefault();
            $(this).closest("section").hide();
        })
    },

    "initSmoothScroll": function () {
        let headerHeight = $('header').height();

        new SmoothScroll('.js-smoothScroll', {offset: headerHeight,});
    },

    "smootScrollOnDropdownItem": function () {
        $('.js-collapseSmoothScroll').on("shown.bs.dropdown", function (e) {
            let scroll = new SmoothScroll();
            let $this = $(this);
            let headerHeight = $('header').height();
            Sliders.updateSliders();
            scroll.animateScroll(
                e.target, // Node to scroll to. ex. document.querySelector('#bazinga')
                e.target, // Node that toggles the animation, OR an integer. ex. document.querySelector('#toggle')
                {
                    speed: 300,
                    easing: 'easeOutQuad',
                    offset: headerHeight,
                } // Classes and callbacks. Same options as those passed into the init() function.
            );
        });
    },

    "inputShownByRadio": function () {
        $('body').on('click', '.js-radio-control', function () {
            $.each($('.js-radio-control'), function (k, v) {
                let $this = $(v);
                let $target = $('#' + $this.attr('aria-controls'));
                if ($target.length) {
                    $this.attr('aria-expanded', $this.prop('checked'));
                    $target.attr('aria-hidden', !$this.prop('checked'));
                }
            });
        })
    },

    //resize text in input if content is too long
    "adaptiveFontSizeBar": function () {
        let adaptiveInput = document.querySelectorAll('.js-adaptive-font-size-bar');
        let minFontSize = 16;
        let maxFontSize = $(window).innerWidth() >= this.mqBreakpoints.md ? 64 : 26;

        for (let i = 0; i < adaptiveInput.length; i++) {
            adaptiveInput[i].addEventListener('keyup', function (e) {

                let textlength = e.target.value.length;
                let inputWidth = e.target.clientWidth;

                e.target.style.fontSize = Math.max(Math.min(inputWidth / (textlength), parseFloat(maxFontSize)), parseFloat(minFontSize)) + 'px';
            });
        }
    },

    "cookieNoticeHandler": function () {
        $('body').on('click', '.js-cookie-handler', function () {
            $('.js-cookie-notice').fadeOut();
        });
    },

    /**
     * Simulate a click on the first link of a nav tab having the class .js-trigger-navtab
     */

    "triggerClickNavTab": function () {
        $('body').on('shown.bs.tab', '.js-trigger-navtab', function (e) {
            // Reinitialize none active tab
            let noneActiveLink = $(this).find('a:not(.active)');
            noneActiveLink.each(function (k, v) {
                let noneActivePanel = $('#' + $(v).attr('aria-controls'));
                let activedLink = noneActivePanel.find('.active');

                activedLink
                    .removeClass('active')
                    .attr('aria-selected', false)
                ;

                $('#' + activedLink.attr('aria-controls'))
                    .removeClass('active')
                    .removeClass('show')
                ;
            });
            // Initialize click on first first of active tab
            let activeLink = $(this).find('a.active');
            let activePanel = $('#' + activeLink.attr('aria-controls'));

            let firstChild = activePanel.find('a:first-child');
            firstChild.addClass('-panelChange');
            firstChild.trigger('click');
            firstChild.removeClass('-panelChange');
        });
    },

    "initBodyScrolllockModal": function () {
        $('.js-bodyScrolllock').on('shown.bs.modal', function () {
            disableBodyScroll(this);
            DatePicker.initPicker();

            //setTimeout compulsory to override the one used in the bodyscrolllock module
            setTimeout(function () {
                document.querySelector('.modal-open').style.removeProperty('overflow');
            }, 0);
        });

        $('.js-bodyScrolllock').on('hidden.bs.modal', function () {
            clearAllBodyScrollLocks();
        });
    },

    "hideCollapse": function (e) {
        // check needed for timepicker to work
        if (!$(e.target).parents('.js-datetimepicker').length) {
            $('.collapse').collapse('hide');
        }
    },

    "preventCollapsePropagation": function () {
        $('.collapse').on("click.bs.collapse", function (e) {
            if (($(e.target).is('input') || $(e.target).is('label')) && !$(e.target).parents('.js-datetimepicker').length) {
                e.stopPropagation();
            }
        });
    },

    "preventDropdownpropagation": function () {
        $('.js-preventDropdownPropagation').on("click.bs.dropdown", function (e) {
            e.stopPropagation();
        });
    },

    "jsSameHeight": function () {
        // Basic options
        $('.js-same-height').matchHeight(false);
        $('.js-container-same-height').matchHeight(false);

        $('body')
            .on('show.bs.dropdown', '.js-trigger-sameheight', this.jsSameHeightUpdate)
            .on('hide.bs.dropdown', '.js-trigger-sameheight', this.jsSameHeightUpdate)
        ;
    },

    "jsSameHeightUpdate": function () {
        // More complex options (update on dropwdown)
        $('.js-same-height').removeAttr('style');
        setTimeout(function () {
            $('.js-same-height').matchHeight();
            $('.js-container-same-height').matchHeight();
        }, 100);

    },

    "subDropdownInit": function () {
        let self = this;
        $('.dropdown-subitem .dropdown-toggle').on("click", function (e) {
            e.preventDefault();
            $(this).siblings('.dropdown-menu').toggleClass('show');
            //avoids the parent toggle menu to close on click
            e.stopPropagation();
            self.jsSameHeightUpdate();

        });

        $('.collapse-subitem *[data-toggle="collapse"]').on("click", function (e) {
            //avoids the parent toggle menu to close on click
            e.preventDefault();
            $(this).siblings('.collapse').collapse('toggle');
            e.stopPropagation();
        });
    },


    "handleDisplayStep": function () {

        let showCorrectStep = function (currentStep) {
            // Hide all steps
            $.each(steps, function (k, v) {
                let step = $(v);
                step.attr('aria-hidden', true)
            });

            // Show current step
            $('.js-display-step[data-step=' + currentStep + ']').attr('aria-hidden', false);
        };

        let steps = $('.js-display-step');
        if (steps.length) {
            let currentStep = 1;

            $('body').on('click', '.js-display-trigger-inc', function () {
                currentStep += 1;
                showCorrectStep(currentStep);
            });

            $('body').on('click', '.js-display-trigger-dec', function () {
                currentStep -= 1;
                showCorrectStep(currentStep);
            });
            $('body').on('click', '.js-display-trigger-start', function () {
                currentStep = 1;
                showCorrectStep(currentStep);
            });
        }
    },

    "checkIfInViewPort": function (el) {
        let elRect = el.getBoundingClientRect();
        let elTop = elRect.top;
        let elBottom = elRect.bottom;

        let isVisible = (elTop >= 0) && (elBottom <= window.innerHeight);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;

    },

    "closeOtherModalOnModalOpening": function () {
        let modalBtns = $(".btn[data-toggle=modal]");

        modalBtns.on("click", function () {
            $(".modal.show").modal('toggle');

            if($("navbar-toggler").attr("aria-expanded", "true")) {
                $("navbar-toggler").click();
            }
        });
    },

    "initCounter": function () {
        const counters = document.querySelectorAll('.counter');

        counters.forEach(counter => {
            counterUp(counter, {
                duration: 1000,
                delay: 16,
            });
        });
    },

    "stickyPolyfill": function () {
        var elements = document.querySelectorAll('.map__panel .btn__close');
        Stickyfill.add(elements);
        if ($(window).innerWidth() >= this.mqBreakpoints.lg) {
            elements = document.querySelectorAll('.js-sticky');
            Stickyfill.add(elements);
            elements = document.querySelectorAll('.search__sticky, .search.-results');
            Stickyfill.add(elements);
        }
        if ($(window).innerWidth() >= this.mqBreakpoints.xl) {
            elements = document.querySelectorAll('.search__pannel');
            Stickyfill.add(elements);
        }

        let stickyEl = Stickyfill.stickies;

        stickyEl.forEach(el => {
            el._node.parentNode.classList.add('js-stickyParent');
            $(el._node).on('shown.bs.dropdown', function() {
                el.refresh();
            }).on('hidden.bs.dropdown', function() {
                el.refresh();
            });
        });
    },

    "removeStickyTopOnIE": function () {
        // let userAgent = this.getMobileOperatingSystem();
        // if (userAgent === "IE" && $(window).innerWidth() >= this.mqBreakpoints.lg) {
        //     $('.js-no-sticky').css("top", 0);
        // }
    },

    "debounce": function (func, wait, immediate) {
        return function () {
            let context = this;
            let args = arguments;
            let later = function () {
                self.timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !self.timeout;
            clearTimeout(self.timeout);
            self.timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    "unScrollTopOnClosedIframe":  function() {
        document.addEventListener('webkitfullscreenchange', function (e) {
            let doJob = false;
            if (!window.screenTop && !window.screenY && Common.getPlatform() != 'Mac') {
                doJob = true;

            } else {
                // chrome doesn't not change screenTop and screenY but even scroll to top
                if (Common.getPlatform() == 'Mac' || Common.getBrowser() == 'chrome') {
                    doJob = true;
                }
            }
            if (doJob) {
                let media = $(e.target);
                if (media.length) {
                    window.scrollTo(0, media.offset().top)
                }
            }
        }, false);
    },

    "sideMenuOpen": function() {
        let sideMenu = document.querySelector('.js-sideNav');

        if(sideMenu) {
            let activeElement = sideMenu.querySelector('.dropdown-item.active');
            let parentDropdown = activeElement.closest('.dropdown');

            $(parentDropdown).find('.dropdown-toggle').dropdown('toggle');
        }
    },

    "initObjectFillPolyfill": function() {
        let objectFitEl = document.querySelectorAll('.js-objectfit');
        objectFitEl.forEach(el => objectFitPolyfill(el));
    },

    "toggleDropdownOnInit": function() {
        $(".js-openedDropdown .dropdown-toggle").dropdown('toggle');
    },

    "collapseHashNavigation": function() {
        $('body').on('shown.bs.collapse', function (e) {
            //relative path needed to work on ie
            window.location.hash = e.target.hash;
            history.replaceState({}, "", window.location);

            if ($(e.target).closest('.search__tile').length === 0) {
                let scroll = new SmoothScroll();
                let headerHeight = $('header[role="banner"]').innerHeight();

                scroll.animateScroll(
                    e.target, // When pannel change, click its first child and scrolls to it
                    e.target, // Node clicked
                    {
                        speed: 800,
                        easing: 'easeOutQuad',
                        offset: headerHeight + 50,
                    }
                );
            }
        });

        var hash = window.location.hash,
            $collapse = $('[data-toggle="collapse"][data-target="' + hash + '"]');

        if (hash && $collapse) {
            $collapse.click();
        }
    },

    "loadRelatdedPrintStyle": function() {
        let btnPrint = document.querySelectorAll('.js-printsheetsSwap');

        btnPrint.forEach(btn => {
            btn.addEventListener('click', (e) => {
                let sheet = e.target.dataset.sheet;
                let body = document.querySelector('body');

                //removing any pritn styles before adding a new one
                if(body.classList.length) {
                    let bodyPrintClasses = [...body.classList].filter(bodyClass => bodyClass.includes('print'));
                    bodyPrintClasses.forEach(bodyClass => body.classList.remove(bodyClass));
                }

                if(sheet) {
                    body.classList.add(sheet);
                }

                window.print();
            });
        });
    },

    "initBackButtons": function() {
        let backBtns = document.querySelectorAll('.js-backButton');

        if(backBtns) {
            backBtns.forEach(btn =>  btn.addEventListener('click',  (e) => window.history.back()));
        }
    },
};
$(window).resize(function () {
    Sliders.updateSliders();
    Common.initObjectFillPolyfill();
});

$(document).ready(function () {

    let $prevThat = "";
    let $that = "";

    $('body').on('click', '[data-toggle="megamenu"]', function (e) {
        $prevThat = $that;
        $that = $(this);
        var selector = $that.attr('data-target') || $that.attr('href'),
            $target = $(selector),
            isExpanded = $that.attr("aria-expanded") === "true" ? "false" : "true";


        if ($that[0].hasAttribute('href') && !$that.attr('href').match(/^#/) && $(window).innerWidth() < Common.mqBreakpoints[$that.closest("[class*='navbar-expand-']").attr('class').match(/navbar-expand-([a-z]{2})/)[1]]) {
            e.preventDefault();
        }

        var openedParents = $target.parents('.navbar-collapse.show');
        // closes already opened megamenu when another one is selected on desktop
        if (!openedParents.length && $prevThat && $prevThat[0] !== $that[0]) {
            $prevThat.attr("aria-expanded", "false");
            $prevThat.siblings('.megamenu-menu.show').toggleClass('show', false);
        }

        $that.attr("aria-expanded", isExpanded);
        $target.toggleClass('show');


        var shown = $target.hasClass('show');
        // Update aria property on all toggler
        $('[data-toggle="megamenu"]')
            .filter('[data-target="' + selector + '"], [href="' + selector + '"]')
            .attr('aria-expanded', shown ? 'true' : 'false')
        ;

        // Hide sub menu when closing parent
        if (!shown) {
            $target.find('.megamenu-menu.show').removeClass('show');
            $target.find('[data-toggle="megamenu"]')
                .filter('[data-target="' + selector + '"], [href="' + selector + '"]')
                .attr('aria-expanded', 'false')
            ;

            clearAllBodyScrollLocks();
        } else {
            if($(window).innerWidth() < Common.mqBreakpoints[$that.closest("[class*='navbar-expand-']").attr('class').match(/navbar-expand-([a-z]{2})/)[1]]) {

                disableBodyScroll($target);
            }
        }

        $('body').toggleClass('has-megamenu', $('.megamenu-menu.show').length > 0 || $('#main-nav').hasClass('show'));

        if($(window).innerWidth() < Common.mqBreakpoints[$that.closest("[class*='navbar-expand-']").attr('class').match(/navbar-expand-([a-z]{2})/)[1]]) {
            $(".modal.show").modal('toggle');
        }
    });

    $('body').on('click', function (e) {
        if ($('body.has-megamenu').length === 0) {
            return;
        }

        let menuClicked = false;

        $('[data-toggle="megamenu"]').each(function () {
            let $that = $(this),
                selector = $that.attr('data-target') || $that.attr('href')
            ;

            if (this.contains(e.target) || $(selector)[0].contains(e.target)) {
                menuClicked = true;
            }

        });

        //closes sub menus when clicking outside menu
        if(!menuClicked) {
            $('body').removeClass('has-megamenu');
            $('.megamenu.show').removeClass('show');
            $('.megamenu-toggle[aria-expanded="true"]').attr("aria-expanded", false);
        }

        if (($(e.target).attr('data-toggle') && $(e.target).attr('data-toggle') === 'modal') || !menuClicked) {
            $('[data-toggle="megamenu"][aria-expanded="true"]').each(function () {
                let $that = $(this);

                if ($that.attr('aria-expanded') === 'true') {
                    $that.trigger('click');
                    $('.megamenu.show').removeClass('show');
                    $('.megamenu-toggle[aria-expanded="true"]').attr("aria-expanded", false);
                }
            });
        }
    });

    $('[data-toggle="popover"]').popover({
        html: true,
        sanitize: false,
        placement: 'top'
    });

    //forEach polyfill
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
    //remove polyfill
    (function (arr) {
        arr.forEach(function (item) {
            if (item.hasOwnProperty('remove')) {
                return;
            }
            Object.defineProperty(item, 'remove', {
                configurable: true,
                enumerable: true,
                writable: true,
                value: function remove() {
                    this.parentNode.removeChild(this);
                }
            });
        });
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

    //closest polyfill

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }


    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType == 1);
            return null;
        };
    }


    Sliders.sliderInit();
    Sliders.initNormalSlider();
    Sliders.initFullscreen();
    Sliders.initHashNavigation();
    Sliders.updateSliderAfterCollapseClose();
    Sliders.initCoverflow();
    Sliders.initSliderOnTabAndDropdown();
    Charts.initCharts();
    Pannel.initPannel();
    Common.preventCollapsePropagation();
    Common.preventDropdownpropagation();
    Common.closeFullWidthAlert();
    Common.setAppLinkToGoodOS();
    Common.jsSameHeight();
    svg4everybody();
    Common.watchVisibility();
    Common.initSmoothScroll();
    Tabs.initTabsSmoothScroll();
    Tabs.allowNoTabsSelected();
    Common.inputShownByRadio();
    Tabs.triggerClickNavTab();
    Common.adaptiveFontSizeBar();
    DatePicker.initPicker();
    Common.initBodyScrolllockModal();
    Common.subDropdownInit();
    Common.handleDisplayStep();
    Tabs.closeAccordionOnContentClick();
    Tabs.stopTabPropagation();
    Common.closeOtherModalOnModalOpening();
    Common.initCounter();
    Common.smootScrollOnDropdownItem();
    Map.initMap();
    SelectForms.selectFormsInit();
    Autocomplete.initAutocomplete();
    Common.stickyPolyfill();
    Common.unScrollTopOnClosedIframe();
    Common.sideMenuOpen();
    Common.cookieNoticeHandler();
    Common.initObjectFillPolyfill();
    Common.toggleDropdownOnInit();
    Common.initBackButtons();
    Tabs.initHistoryNavigation();
    Tabs.tabDropdownSynchronize();
});

window.addEventListener('DOMContentLoaded', (event) => {
    Common.collapseHashNavigation();
    Common.loadRelatdedPrintStyle();
});

export default Common;