const Map = {
    "maps": document.querySelectorAll('.js-maps'),
    "mapPanels": document.querySelectorAll('.js-mapPanelContainer'),
    "markers": [],

    "initMap": function () {
        let self = this;
        if (this.maps.length) {
            this.maps.forEach(newMap => {
                let center = {lat: 49.815274, lng: 6.096624};
                let styles = [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#ada2a2"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#bdb6b6"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": "0"
                            },
                            {
                                "lightness": "-15"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": "-100"
                            },
                            {
                                "lightness": "30"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#a6d6ee"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ];
                let map = new google.maps.Map(newMap, {
                    zoom: 9,
                    center: center,
                    styles: styles,
                });

                let markerImage = {
                    url: "../img/svg/marker.svg",
                    scaledSize: new google.maps.Size(10, 16), // scaled size
                };

                let marker = new google.maps.Marker({
                    position: {lat: 49.599145, lng: 6.133403},
                    map: map,
                    icon: markerImage,
                    class: 'js-marker'
                });

                this.markers.push(marker);

                map.addListener('click', (e) => {
                    let openedPanel = document.querySelector('.js-mapPanelPanel.open');
                    if (openedPanel) {
                        openedPanel.classList.remove('open');
                    }
                });

                google.maps.event.addListener(marker, 'click', (function (marker) {
                    return function () {
                        self.togglePannel(newMap);
                    }
                })(marker));

            });

            this.initCloseBtn();
            document.addEventListener('click', function (e) {
                let openedPanel = document.querySelector('.js-mapPanelPanel.open');
                if (openedPanel && !e.target.closest('.js-mapPanelContainer')) {
                    openedPanel.classList.remove('open');
                }

            });
        }
    },

    "togglePannel": function (map) {
        let mapContainer = map.closest('.js-mapPanelContainer');
        let panel = mapContainer.querySelector('.js-mapPanelPanel');

        //in case of opened panel, closing it before changing it's content and reopening it
        panel.classList.remove('open');

        //change panel content here
        panel.classList.add('open');
    },

    "initCloseBtn": function () {
        let pannelCloseBtns = document.querySelectorAll('.js-mapPanelPanel .btn__close');

        pannelCloseBtns.forEach(btn => {
            btn.addEventListener('click', function (e) {
                e.target.closest('.js-mapPanelPanel').classList.remove('open');
            });
        })
    },
};

export default Map;