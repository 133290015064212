import $ from "jquery";
import Common from './common.js';
import {disableBodyScroll} from "body-scroll-lock";
import SmoothScroll from "smooth-scroll";

const Tabs = {
    /**
     * Simulate a click on the first link of a nav tab having the class .js-trigger-navtab
     */
    "triggerClickNavTab": function () {
        $('body').on('shown.bs.tab', '.js-trigger-navtab', function (e) {
            // Reinitialize none active tab
            let noneActiveLink = $(this).find('a:not(.active)');
            noneActiveLink.each(function (k, v) {
                let noneActivePanel = $('#' + $(v).attr('aria-controls'));
                let activedLink = noneActivePanel.find('.active');

                activedLink
                    .removeClass('active')
                    .attr('aria-selected', false)
                ;

                $('#' + activedLink.attr('aria-controls'))
                    .removeClass('active')
                    .removeClass('show')
                ;
            });

            // Initialize click on first first of active tab
            let activeLink = $(this).find('a.active');
            let activePanel = $('#' + activeLink.attr('aria-controls'));

            let firstChild = activePanel.find('a:first-child');
            firstChild.addClass('-panelChange');
            firstChild.trigger('click');
            firstChild.removeClass('-panelChange');
        });
    },

    "closeAccordionOnContentClick": function () {
        let accordions = $('.js-accordionCollapse');

        accordions.on('click', function () {
            $(this).collapse('toggle');
        });
    },

    "initTabsSmoothScroll": function () {
        let self = this;
        let headerHeight = $('header').height();

        $('body').on('shown.bs.tab', '.js-nav-tabs-scroll', function (e) {
            let scroll = new SmoothScroll();
            let $this = $(e.target);

            if ($(window).innerWidth() >= Common.mqBreakpoints.lg && !$this.hasClass('search__tile') && !$this.hasClass('js-stopTabPropagation')) {
                scroll.animateScroll(
                    document.querySelector('#' + e.target.getAttribute('aria-controls')), // Node to scroll to. ex. document.querySelector('#bazinga')
                    e.target, // Node that toggles the animation, OR an integer. ex. document.querySelector('#toggle')
                    {
                        speed: 800,
                        easing: 'easeOutQuad',
                        offset: headerHeight,
                    } // Classes and callbacks. Same options as those passed into the init() function.
                );

            } else if ($this.hasClass('-panelChange') && $(window).innerWidth() <= Common.mqBreakpoints.md) {
                scroll.animateScroll(
                    e.target, // When pannel change, click its first child and scrolls to it
                    e.target, // Node clicked
                    {
                        speed: 800,
                        easing: 'easeOutQuad',
                        offset: headerHeight + 50,
                    }
                );
            } else if ($this.hasClass('search__tile')) {
                let targetPanel = document.querySelector('#' + e.target.getAttribute('aria-controls')); // Node to scroll to. ex. document.querySelector('#bazinga')
                let pannelposition = targetPanel.getBoundingClientRect();

                //avoids screen jumps when panel is opened
                if (pannelposition.top !== headerHeight + 100) {
                    scroll.animateScroll(
                        targetPanel,
                        e.target,
                        {
                            speed: 800,
                            easing: 'easeOutQuad',
                            offset: headerHeight + 100,
                        }
                    );
                }

            }
        });

        this.changeHashOnTabShow();
    },

    'allowNoTabsSelected': function () {
        let self = this;


        $('.js-allowNoTabsSelected').on('click', function (e) {
            let $this = $(this);

            //only check active class (not the show), causes bug when using both
            if ($this.hasClass('active')) {

                let panel = $($this.attr("data-target"));

                $this.removeClass('active');
                $this.attr('aria-selected', false);
                panel.removeClass('active');

                //creating an event when all tabs are closed
                $this.trigger('closedTab');

                return false;
            } else {
                $this.tab('show');
                // window.location.hash = this.hash;
                history.pushState({}, "", this.hash);
            }
        });

    },

    'hideTab': function (tab) {
        let panel = $(tab.attr("href"));
        tab.tab('hide');
    },

    'changeHashOnTabShow': function () {
        $('.nav-tabs a:not(".js-allowNoTabsSelected")').click(function (e) {
            $(this).tab('show');
            history.pushState({}, "", this.hash);
        });
    },

    'stopTabPropagation' : function() {
        $('.js-stopTabPropagation').on('click', function(e) {
            e.stopPropagation();
            $(this).closest('[data-toggle="tab"]').tab('show');
        })
    },

    initHistoryNavigation: function() {
        let self = this;

        $('body').on('show.bs.tab', function(e) {
            //relative path needed to work on ie
            window.location.hash = e.target.hash;
            history.replaceState({}, "", window.location);
        });

        self.selectTabFromHash();


        window.addEventListener('popstate', e => {
          self.selectTabFromHash();
        });

    },

    selectTabFromHash: function() {
        let hash = window.location.hash,
            $tab = $('[data-toggle="tab"][href="' + hash + '"]');

        if (hash && $tab) {
            $tab.tab('show');
        }
    },

    'tabDropdownSynchronize': function() {
        $('body').on('click', '.js-tab-dropdown-synchronize', function() {
            let clickedElement = $(this);
            let synchronizeWith = $this.attr('data-synchronize');
            let synchronizedElements = $('.js-tab-dropdown-synchronize[data-synchronize="'+synchronizeWith+'"]');

            $.each(synchronizedElements, function() {
                let targetElement = $(this);
                if (clickedElement != targetElement) {
                    targetElement.trigger('click');
                }
            });
        });
    }
};

export default Tabs;