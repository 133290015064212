import '../scss/fret.scss';
import Common from './common.js';
import $ from 'jquery';
import 'bootstrap';
import SelectTabs from './select-tabs';

let estimateBuildFormAction = function(action, domainLinkId, offerLinkId, linkHref) {

    let finalAction = action;

    if (action.indexOf('?') == -1) {
        finalAction += '?';
    }

    if (domainLinkId !== null && typeof domainLinkId != 'undefined') {
        finalAction += '&domain='+domainLinkId;
    }

    if (offerLinkId !== null && typeof offerLinkId != 'undefined') {
        finalAction += '&offer='+offerLinkId;
    }

    if (linkHref !== null && typeof linkHref != 'undefined') {
        finalAction += linkHref;
    }

    return finalAction;
};

/**
 * Modify the form action to automatically select, currently selected buttons, when we go to the next step
 */
let estimateJobsInit = function() {
    let tabs = $('.nav-tabs.-as-button a');
    let form =  $('.js-form-estimation form');

    // Action only on form estimation
    if ($(tabs).length && $(form).length) {

        // Used letiables to build the form action
        let initialAction = form.attr('action');
        let selectedOfferLinkId  = $('.js-estimate-offer-link.active').attr('data-selected');
        let selectedDomainLinkId = $('.js-estimate-domain-link.active').attr('data-selected');
        let selectedTabLinkHref  = '#' + $('.js-estimate-domain-link.active').attr('aria-controls');

        // Buid the form action at initialization to remember choosing domain and offer for the step 2, 3, 4
        form.attr('action', estimateBuildFormAction(initialAction, selectedDomainLinkId, selectedOfferLinkId, selectedTabLinkHref));

        // Add listener to change form action when you click on offers links
        let offersLinks =  $('.js-estimate-offer-link');
        offersLinks.on('click', function() {
            selectedOfferLinkId = $(this).attr('data-selected');
            form.attr('action', estimateBuildFormAction(initialAction, selectedDomainLinkId, selectedOfferLinkId, selectedTabLinkHref));
        });

        // Add listener to change form action when you click on domains links
        let domainsLinks = $('.js-estimate-domain-link');
        domainsLinks.on('click', function() {
            selectedDomainLinkId = $(this).attr('data-selected');
            selectedTabLinkHref = '#' + $(this).attr('aria-controls');
            form.attr('action', estimateBuildFormAction(initialAction, selectedDomainLinkId, selectedOfferLinkId, selectedTabLinkHref));
        });
    }
};

let organisationServicesHandler = function() {
    $('.js-organisation-entity').matchHeight({ property: 'min-height' });
}


const showPannelFromUrl = function() {
    let target = $(":target");

    if(target.length > 0 && $(window).innerWidth() >= Common.mqBreakpoints.lg) {
        //the target is the panel, it selects the button that controls the target
        $('.js-tabs a[aria-controls=' + target.attr('id') + ']').tab('show');
    } else if (target.length > 0 && $(window).innerWidth() < Common.mqBreakpoints.lg) {
        //the target is the selected dropdown item previously selected
        //will open the corresponding panel, change the dropdown btn text and
        //set the state of matching dropdown item to active
        SelectTabs.changeTab(target);
    }

    estimateJobsInit();
};

let changeRoundedPannelBtn = function() {
        let activeTab = $('.rounded-pannel__nav .nav-link.active');

        if(!activeTab.length) {
            $('.rounded-pannel').removeClass('active');
            $('.rounded-pannel__nav .nav-link').first().addClass('first');
        } else {
            $('.rounded-pannel').addClass('active');
            $('.rounded-pannel__nav .nav-link').first().removeClass('first');
        }
};

let initRoundedPannelBtnBehaviour = function() {
    $('.rounded-pannel__nav .nav-link').on('shown.bs.tab', function() {
        changeRoundedPannelBtn();
    });

    $('.rounded-pannel__nav .nav-link').on('closedTab', function() {
        changeRoundedPannelBtn();
    });

    $('.rounded-pannel__nav .nav-link.active').on('click', function() {
        clearTimeout(timeOut);
    });
    $('.rounded-pannel .tab-pane.active').on('click', function() {
        clearTimeout(timeOut);
    });

    // // Remove active state 2.5s after the document ready
    let timeOut = setTimeout(function() {
        $('.rounded-pannel__nav .nav-link.active').trigger('click');
    }, 2500);
};

let dropdownWithInput = function() {
    $('.js-dropdown-with-input').on('click', function(e) {
        e.stopPropagation();
    });
    $('.js-dropdown-with-input input[type=checkbox]').on('change', function(){
        $(this).closest('label').toggleClass('active', $(this).is(':checked'));
    })
};

$(document).ready(function () {
    SelectTabs.selectTabsInit();
    organisationServicesHandler();
    showPannelFromUrl();
    initRoundedPannelBtnBehaviour();
    dropdownWithInput();
});