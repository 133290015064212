import Common from './common.js';
import 'whatwg-fetch';
import DisableAutocomplete from './disableAutocomplete';

const Autocomplete = {
    bus: ["BUS", "FB_BUS"],
    train: ["HIGH_SPEED_TRAIN", "RB_TRAIN", "TER_TRAIN", "INTER_CITY_TRAIN"],
    defaultTemplate : "",
    busTemplate : "",
    trainTemplate : "",
    errorTemplate : "",
    disabledAutofillInputs: [],

    initAutocomplete: function () {
        let self = this;
        let dropdownSelects = document.querySelectorAll('.js-inputSelect');

        if(dropdownSelects.length) {
            this.defaultTemplate = document.querySelector('.defaultResultsTemplate').innerHTML || "";
            this.busTemplate = document.querySelector('.busResultsTemplate').innerHTML || "";
            this.trainTemplate = document.querySelector('.trainResultsTemplate').innerHTML || "";
            this.errorTemplate = document.querySelector('.errorResultsTemplate').innerHTML || "";

            dropdownSelects.forEach(select => {
                let url = select.dataset['url'];
                this.fetchResults(url, select);

                this.autoCompleteHandler(select);
            });

            if(window.chrome || window.navigator.userAgent.match("CriOS")) {
                let forms = document.getElementsByTagName('form');
                forms.forEach(form => form.addEventListener('submit', (e) => self.handleFormSubmit(e)));
            }
        }
    },

    autoCompleteHandler: function(select) {
        let self = this;

        select.addEventListener('focus', self.focusInHandler);

        select.addEventListener('keydown', e => self.keydownHandler(e, self));

        document.addEventListener('click', self.clickHandler);

        let disabledInput = new DisableAutocomplete(select.name, select.id);

        if(window.chrome || window.navigator.userAgent.match("CriOS")) {
            select.name = disabledInput.giveRandomName();
            this.disabledAutofillInputs.push(disabledInput);
        }
    },

    fetchResults: function(url, select) {
        let self = this;
        fetch(url).then(function(response) {
            return response.json();
        }).then(function(j) {
           self.populateResults(j, select);
        }).catch(function(err){
            self.fetchErrorHandler(select);
        })
    },


    fetchErrorHandler: function(select) {
        let container = select.closest('.select-autocomplete');
        let list = container.querySelector('.simplebar-content');

        this.removeCurrentResults(list);

        list.innerHTML += this.errorTemplate;
    },

    populateResults: function(results, select) {
        let container = select.closest('.select-autocomplete');
        let list = container.querySelector('.simplebar-content');

        results.forEach(item => {
            if(this.bus.includes(item.StationType)) {

                let template = this.setStationName(this.busTemplate, item);
                list.appendChild(template);

            } else if(this.train.includes(item.StationType)) {
                let template = this.setStationName(this.trainTemplate, item);
                list.appendChild(template);
            } else {
                let template = this.setStationName(this.defaultTemplate, item);
                list.appendChild(template);
            }
        });

        this.initSelectHandler(list);
    },

    setStationName: function(template, item) {
        //transforms string to nodeElement without adding the content in the dom
        let fragment = document.createRange().createContextualFragment(template);

        let stationName = fragment.querySelector('.js-resultsName');
        stationName.textContent = item.StationName;

        if(item.StationExtId) {
            stationName.dataset.trainId = item.StationExtId || "";
        }

        return fragment;
    },

    removeCurrentResults: function(list) {
        let listItem = list.querySelectorAll('.dropdown-item:not(.js-selectLoader)');
        listItem.forEach(item => item.remove());
    },

    initSelectHandler: function(list) {
        let listItem = list.querySelectorAll('.js-results');

        listItem.forEach(item => {
            item.addEventListener('click', e => this.selectHandler(e));
            item.addEventListener('keydown', e => this.selectKeydownHandler(e));
        })
    },

    selectHandler: function(e) {
        let item = e.target.querySelector('.js-resultsName');
        let container = item.closest('.select-autocomplete');
        let adressInput = container.querySelector('.js-adress');
        let trainIdInput = container.querySelector('.js-trainId');

        adressInput.value = item.textContent;
        trainIdInput.value = item.dataset.trainId;

        $(adressInput).dropdown('hide');

        this.populateModalInput(adressInput);
    },

    selectKeydownHandler: function(e) {
        let key = e.key;
        let container = e.target.closest('.select-autocomplete');
        let adressInput = container.querySelector('.js-adress');
        let list = e.target.closest('.simplebar-content');
        let listItems = list.querySelectorAll('.js-results');

        if(key === 'Enter' || key === " " || key === "Spacebar") {
            e.preventDefault();
            this.selectHandler(e);
        } else if(key === "Home") {
            e.preventDefault();
            listItems[0].focus();
        } else if(key === "End") {
            e.preventDefault();
            listItems[listItems.length - 1].focus();
        } else if(key === "Escape" || key === "Esc") {
            $(adressInput).dropdown('hide');
        }
    },

    onKeypressHandler: function(e) {
        let self = this;
        let select = e.target;
        let container = select.closest('.select-autocomplete');
        let list = container.querySelector('.simplebar-content');

        self.removeCurrentResults(list);

        let fetchQuery = Common.debounce(function () {
            let query = select.value;
            self.populateModalInput(select);
            self.fetchResults(`${select.dataset.url}${query}`, select);
            self.removeTrainId(container);
        }, 250);

        fetchQuery();
    },

    keydownHandler: function(e) {
        let key = e.key;
        let container = e.target.closest('.select-autocomplete');
        let list = container.querySelector('.simplebar-content');

        if(key === "ArrowUp"  || key === "ArrowDown"  || key === "Down" || key === "Up") {
            e.preventDefault();
            list.querySelector('.js-results').focus();
        } else if (key === "Tab" &&  !e.shiftKey) {
            $(e.target).dropdown('hide');
            this.focusNextInput(container);
        }  else {
            this.onKeypressHandler(e);
        }
    },

    clickHandler: function(e) {
        if(!e.target.closest('.dropdown')) {
           $('.js-adress').dropdown('hide');
        }
    },

    focusInHandler: function(e) {
        let select = e.target;
        $('.js-adress').dropdown('hide');
        $(e.target).dropdown('show');
    },

    focusNextInput: function(container) {
        let form = container.closest('form');
        let focusableElements = [...form.querySelectorAll('input, [tabindex]:not([tabindex="-1"]):not(.js-resultsName), button ')];
        let nextElIndex = focusableElements.indexOf(document.activeElement) + 1;
        focusableElements[nextElIndex].focus();
    },

    removeErrorMessage: function (el) {
        let container = el.closest('.form-via');

        let errorMessage = container.find('.js-error');
        errorMessage.remove();
    },

    populateModalInput: function(el) {
        let container = el.closest('.form-group');

        let advancedSearchmodal = document.getElementById('advancedSearch');
        let adressInput = el.value;
        let IdInput = container.querySelector('.js-trainId').value;

        if(container.classList.contains('js-departure')) {
          this.changeAllInputInPage('.js-departure', adressInput, IdInput);

        } else if(container.classList.contains('js-arrival')) {
            this.changeAllInputInPage('.js-arrival', adressInput, IdInput);
        }
    },

    changeAllInputInPage: function(inputClass, adressInput, idInput) {
        let inputGroup = [...document.querySelectorAll(inputClass)];
        let selects = inputGroup.map(group => group.querySelector('.js-adress'));

        selects.forEach(select => {
            select.placeholder = adressInput;
            select.value = adressInput;
        });


        let modalIds = inputGroup.map(group => group.querySelectorAll('.js-trainId'));
        modalIds.forEach(modalId => {
            modalId.value = idInput;
        });

    },

    removeTrainId: function(container) {
        $(container).find('.js-trainId').val("");
    },

    handleFormSubmit: function (e) {
        e.preventDefault();
        this.disabledAutofillInputs.forEach(obj => {
            let input = document.getElementById(obj.id);
            input.name = obj.giveBackOriginalName();
        });

        e.target.submit();
    }
};

export default Autocomplete;