import $ from "jquery";
import Common from './common.js';

const SelectForms = {
    "selectFormsInit": function () {
        let self = this;
        const selectItems = $('.js-select');

        if ($(selectItems)) {
            $(selectItems).each(function () {
                let $thisBtn = $(this).closest('.dropdown').find('.dropdown-toggle');
                let $thisInput = $(this).closest('.dropdown').find('.select-form-input');

                $(this).on("click", function () {

                    if (!$(this).hasClass('-inline')) {
                        self.changeInputText($(this), $thisInput);
                        self.changeButtontext($(this), $thisBtn, ' km');

                    } else {
                        self.changeButtontext($(this), $thisBtn);
                        self.showForm($(this));
                    }

                });
            });
        }
    },

    "selectInputInit": function() {
        let self = this;
        const selectItems = $('.js-inputSelectItem');

        if ($(selectItems)) {
            $(selectItems).each(function () {
                let $thisBtn = $(this).closest('.dropdown').find('.js-inputSelect');

                $(this).on("click", function () {
                        self.changeInputText($(this), $thisBtn);
                        self.showForm($(this));
                });
            });
        }
    },

    "changeInputText": function(item, input) {
        let itemText = item.find('.js-inputSelectText').text();
        input.val(itemText);
    },

    "changeButtontext": function (elem, btn, text) {
        let additionaltext = $(window).innerWidth() >= Common.mqBreakpoints.md ? text : false;
        let BtnText = btn.find('.changing-text');

        if (additionaltext) {
            BtnText.text(elem.text() + text);
        } else {
            BtnText.text(elem.text());
            btn.addClass('active');
        }

    },

    "showForm": function (elem) {
        let selectForm = elem.closest('.js-selectContainer');
        let selects = selectForm.find('.js-dropdown');
        let activeSelects = selects.filter('.active');

        if (activeSelects.length === selects.length) {
            let form = selectForm.find('.js-selectForm');
            form.css("display", "flex");
        }

    },
};

export default SelectForms;